  .wrapper {
      text-align: center;
      align-items: center;
      font-family: 'Londrina Solid';
      background-color: var(--bs-body-color);
      font-size: 1.2rem;
      color: white;
  }

  .wrapper a {
    color: white;
  }

  @media (max-width: 992px) {
      .wrapper {
          padding: 2rem;
      }

      .wrapper h1 {
          font-size: 3.75rem;
      }
  }

  @media (min-width: 992px) {
      .wrapper h1 {
          font-size: 6rem;
          margin-left: 2rem;
      }
  }