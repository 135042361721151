.wrapper {
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    line-height: 16px;
    font-family: 'PT Root UI';
    font-weight: bold;
    padding: 0px 12px 0px 12px;
    transition: all 0.125s ease-in-out;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  .icon {
    margin-right: 0.4rem;
  }
  
  .icon > svg {
    max-height: 14px;
    opacity: 0.5;
  }
  
  @media (max-width: 992px) {
    .icon > svg {
      max-height: 17px;
    }
  }
  
  .btnEnabled {
    cursor: pointer;
  }
  
  .btnDisabled {
    cursor: not-allowed;
  }
  
  .coolInfo {
    border: 1px solid var(--brand-cool-border);
    border-radius: 10px;
    color: var(--brand-cool-dark-text);
  }
  
  .coolInfo:hover {
    background-color: var(--brand-cool-accent);
    color: black;
  }
  
  .coolWallet {
    background-color: var(--brand-cool-accent);
    border: 1px solid var(--brand-cool-border);
    border-radius: 10px;
    color: var(--brand-warm-dark-text);
    padding: 0px 12px 0px 12px;
    transition: all 0.2s ease-in-out;
  }
  
  .coolWallet:hover {
    background-color: var(--brand-color-blue);
    color: white;
    filter: brightness(110%);
  }
  
  .warmInfo {
    border: 1px solid var(--brand-warm-border);
    border-radius: 10px;
    color: var(--brand-warm-dark-text);
  }
  
  .warmInfo:hover {
    background-color: var(--brand-warm-accent);
    color: black;
  }
  
  .warmWallet {
    background-color: var(--brand-warm-accent);
    border: 1px solid var(--brand-warm-border);
    border-radius: 10px;
    color: var(--brand-warm-dark-text);
    padding: 0px 12px 0px 12px;
    transition: all 0.2s ease-in-out;
  }
  
  .warmWallet:hover {
    background-color: var(--brand-color-red);
    color: white;
    filter: brightness(110%);
  }
  
  .whiteInfo,
  .whiteWallet {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: rgb(95, 95, 95);
  }
  
  .whiteInfo:hover,
  .whiteWallet:hover {
    background-color: #e2e3e8;
    color: black;
  }
  
  .whiteActive {
    background-color: #f4f4f8;
    color: black;
  }
  
  .whiteActive .icon {
    color: #d63c5e;
  }
  
  .whiteActiveVoteSubmit {
    background-color: #e2e3e8;
    color: black;
  }
  
  @media (max-width: 992px) {
    .wrapper,
    .button {
      height: 48px;
      font-size: 18px;
    }
  }
  
  .delegateBack {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: var(--brand-cool-dark-text);
    width: 100px;
  }
  
  .delegateBack:hover {
    background-color: #e2e3e8;
    color: var(--brand-cool-dark-text);
  }
  
  .delegatePrimary {
    background-color: var(--brand-cool-dark-text);
    color: white;
    width: 315px;
  }
  
  .delegatePrimary:hover {
    filter: brightness(200%);
  }
  
  .delegateSecondary {
    background-color: var(--brand-color-blue);
    color: white;
    width: 315px;
    border: none;
  }
  
  .delegateSecondary:hover {
    filter: brightness(200%);
  }
  
  .delegateDisabled {
    background-color: var(--brand-color-blue);
    opacity: 0.5;
    color: white;
    width: 315px;
    border: none;
  }
  
  @media (max-width: 992px) {
    .delegatePrimary {
      width: 210px;
    }
  
    .delegateSecondary {
      width: 210px;
    }
  
    .delegateDisabled {
      width: 210px;
    }
  }
